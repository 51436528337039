import React from "react";
import { useQueryParamString } from "react-use-query-param-string";
import Layout from "components/Layout";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Helmet } from "react-helmet";
//import Header from "components/Header";
import AppBar2 from "components/AppBar2";

const StyledPlayer = styled(ReactPlayer)`
  margin-top: 3.5em;
`;

export default function VideoPlayer() {
  const [videoId, setVideoId, initializedVideoId] = useQueryParamString(
    "id",
    ""
  );
  return (
    <>
      <Helmet>
        <title>Savant Video</title>
      </Helmet>
      {/* <Header /> */}
      <AppBar2 />
      <StyledPlayer
        url={`https://cdn.savant.com/${videoId}`}
        controls={true}
        autoplay={true}
        width="98.8vw"
        height="70vh"
      />
    </>
  );
}
